/** ========== */
import { Partytown } from "@builder.io/partytown/react";
// import { useEffect, useState } from "react";
import Head from "next/head";
import Script from "next/script";

import FacebookMessenger from "components/atoms/FacebookMessenger";
import Zalo from "components/atoms/Zalo";
import "styles/globals.scss";

const MyApp = ({ Component, pageProps }) => {
  return (
    <>
      <Head>
        <meta charSet="UTF-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta
          name="facebook-domain-verification"
          content="t6ecnryhghaszvxhpm0phfa9oy6vgv"
        />
        <Partytown debug={false} forward={["dataLayer.push", "fbq"]} />
      </Head>
      <Component {...pageProps} />

      {/* {srcGTM && <Script src={srcGTM}></Script>} */}
      {/* {process.env.NEXT_PUBLIC_GTM_ENABLE && (
        <>
          <Script strategy="lazyOnload" id="Tawk_API">
            {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_ID}');`}
          </Script>
        </>
      )} */}
      {process.env.NEXT_PUBLIC_GTM_ENABLE === "1" && (
        <>
          <Script strategy="lazyOnload" id="Tawk_API" type="text/partytown">
            {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='/assets/js/gtm.js?id='+i+dl;j.type='text/partytown';f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_ID}');`}
          </Script>
        </>
      )}
      <FacebookMessenger />
      <Zalo />
    </>
  );
};

export default MyApp;
