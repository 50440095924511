import Script from "next/script";
import { useEffect, useState } from "react";

function FacebookMessenger() {
  const [loading, setLoading] = useState(false);
  // useEffect(() => {
  //   setTimeout(() => {
  //     setLoading(true);
  //   }, 6000);
  // }, []);
  const handleOnScroll = (e) => {
    if (!loading) setLoading(true);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleOnScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleOnScroll);
    };
  }, []);
  return (
    <>
      {loading ? (
        <>
          <div id="fb-root"></div>
          <div id="fb-customer-chat" className="fb-customerchat"></div>
          <Script
            id="facebook-messenger"
            strategy="lazyOnload"
            type="text/partytown"
          >
            {`
            var chatbox = document.getElementById('fb-customer-chat');
            chatbox.setAttribute("page_id", "103158101213933");
            chatbox.setAttribute("attribution", "biz_inbox");
            
            window.fbAsyncInit = function() {
                FB.init({
                xfbml            : true,
                version          : 'v14.0'
                });
            };

             (function(d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s); js.id = id;
        js.src = 'https://connect.facebook.net/vi_VN/sdk/xfbml.customerchat.js';
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));
        `}
          </Script>
        </>
      ) : (
        <script></script>
      )}
    </>
  );
}

export default FacebookMessenger;
