import Script from "next/script";
import { useEffect, useState } from "react";
const zaloWidget = `<div
class="zalo-chat-widget"
data-oaid="536303807395273679"
data-welcome-message="Rất vui khi được hỗ trợ bạn!"
data-autopopup="0"
data-width="350"
data-height="420"
/>`;
function Zalo() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setLoading(true);
    }, 6000);
  }, []);
  return (
    <>
      {loading ? (
        <>
          <div dangerouslySetInnerHTML={{ __html: zaloWidget }} />
          <Script
            strategy="lazyOnload"
            src="https://sp.zalo.me/plugins/sdk.js"
          ></Script>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default Zalo;
